<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="addQuoteRequestModal" ref="modalEle" tabindex="-1" role="dialog"
         aria-labelledby="modelTitleId"
         aria-hidden="true">
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Dodaj do zapytania ofertowego</h5>
            <button type="button" class="close ms-auto" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div v-if="form" class="container-fluid">
              <div class="row">

                <div class="col-md-5">
                  <label for="dueDate">Ostateczny termin otrzymania<br> odpowiedzi na zapytanie ofertowe:</label>
                  <div class="mb-4" style="max-width: 300px;">
                    <ejs-datepicker
                        id="projectFileProgressId"
                        placeholder="Wybierz zakres dat"
                        :value="form.dueDate"
                        :format="dueDateFormat"
                        :weekNumber="true"
                    ></ejs-datepicker>
                  </div>
                </div>

                <div class="col-md-7">
                  <label for="emails">Wyślij zapytanie ofertowe na poniższe maile:</label>

                  <div v-if="hasError(`emails`)" class="text-danger small">
                    {{ getErrorMessage(`emails`) }}
                  </div>

                  <table class="table">
                    <tbody>
                    <tr v-for="(email, key) in form.emails" :key="email.to">
                      <td>
                        <Vueform>
                          <TagsElement name="emailsTo[]" placeholder="Mail dostawcy"
                                       :max="1"
                                       :native="false"
                                       :items="getSuppliersEmails"
                                       :default="[email.to]"
                                       :value="email.to"
                                       type="email"
                                       :create="true"
                                       @change="changeSupplierEmail('to', key, $event)"
                                       :search="true" autocomplete="off"/>
                        </Vueform>
                      </td>
                      <td>
                        <Vueform>
                          <TagsElement name="emailsDw[]" placeholder="Maile DW"
                                       :items="getSuppliersEmails"
                                       :default="[email.dw]"
                                       :value="email.dw"
                                       type="email"
                                       :create="true"
                                       @change="changeSupplierEmail('dw', key, $event)"
                                       :search="true" autocomplete="off"/>
                        </Vueform>
                      </td>
                      <td>
                        <!-- Przycisk "+" widoczny tylko w ostatnim wierszu -->
                        <template v-if="key == Object.keys(form.emails).length - 1">
                          <button type="button"
                                  class="ms-2 btn btn-outline-success"
                                  @click="addEmailsRow">
                            <i class="bi bi-plus-circle"></i>
                          </button>
                        </template>
                        <!-- a jeśli nie ostatni to pokaż przycisk "-" -->
                        <template v-else>
                          <button type="button"
                                  class="ms-2 btn btn-outline-danger"
                                  @click="removeEmailsRow(key)">
                            <i class="bi bi-dash-circle"></i>
                          </button>
                        </template>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <Vueform>
                    <TextElement name="comment" @change="changeComment($event)" autocomplete="off"
                                 label="Komentarz do zapytania"
                                 placeholder="Komentarz będzie widoczny dla klienta"/>

                  </Vueform>
                </div>
              </div>

              <template v-for="(article, idx) in form.articles" :key="article.id">
                <table class="table">
                  <tbody>
                  <tr>
                    <td colspan="2">#{{ idx + 1 }}</td>
                  </tr>
                  <tr>
                    <td class="widthFirstTd">Nr katalogowy</td>
                    <td>{{ article.catalog_number }}</td>
                  </tr>
                  <tr>
                    <td class="widthFirstTd">Model</td>
                    <td>{{ article.model }}</td>
                  </tr>
                  <tr>
                    <td class="widthFirstTd">Potrzebna ilość</td>
                    <td>
                      <div v-if="hasError(`articles.${idx}.quantity_required`)" class="text-danger small">
                        {{ getErrorMessage(`articles.${idx}.quantity_required`) }}
                      </div>
                      <input class="d-inline-block form-control form-control-sm w-75" type="number" step="0.1" min="0"
                             v-model="article.quantity_required" required>
                      <div class="ms-2 d-inline-block">{{ article.unit_name }}</div>

                      <input type="hidden" v-model="article.unit_id">
                      <input type="hidden" v-model="article.unit_name">
                    </td>
                  </tr>
                  <tr>
                    <td class="widthFirstTd">Producent</td>
                    <td>
                      <p><small>
                        <i class="text-muted">Producent wymagany przez klienta:
                          <b v-if="producentSelectedInProject[article.article_id]">
                            {{ producentSelectedInProject[article.article_id].join(', ') }}
                          </b>
                          <b v-else> Brak</b>
                        </i>
                      </small>
                      </p>
                      <template v-if="article.producer_short_name">
                        <span
                            class="font-weight-bolder"
                            :class="getProducerClass(article.article_id, article.producer_short_name)">
                              {{ article.producer_short_name }}
                            </span>
                      </template>
                      <template v-else>
                        <i>Brak domyślnego producenta</i>
                      </template>
                    </td>
                  </tr>

                  </tbody>
                </table>

              </template>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              <i class="bi bi-x-square me-2"></i>Zamknij
            </button>
            <form @submit.prevent="saveQuoteRequest">
              <button type="submit" class="btn btn-outline-primary me-2" @click="setWithSend(false)"
                      :disabled="form.processing || emailsOk">
                <i class="bi bi-save me-2"></i>Zapisz bez wysyłki
              </button>

              <button type="submit" class="btn btn-primary" @click="setWithSend(true)"
                      :disabled="form.processing || emailsOk">
                <i class="bi bi-send me-2"></i>Wyślij zapytania ofertowe
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {Modal, Tooltip} from "bootstrap";
import {useForm} from "@inertiajs/vue3";
import {DatePickerComponent} from "@syncfusion/ej2-vue-calendars";

export default {
  components: {'ejs-datepicker': DatePickerComponent},
  props: {
    requiredProjectItem: String,
    project: Number | String,
    team: String,
    selectedItems: {
      type: Object,
      default: () => {
      }
    },
  },

  data() {
    return {
      errors: {},
      emailsOk: false,
      quoteRequestNumbers: {},
      modalObj: null,
      dueDateFormat: 'dd.MM.yyyy',
      producentSelectedInProject: [],
      form: useForm({
        dueDate: this.defaultDueDate(),
        with_send: true,
        comment: '',
        emails: {
          0: {
            to: '',
            dw: ''
          }
        }
      })
    }
  },

  watch: {
    selectedItems: {
      immediate: true,
      handler(newItems) {
        if (!newItems) {
          return false;
        }
        this.getDefaultProducentForSelectedItem();

        this.form = useForm({
          dueDate: this.defaultDueDate(),
          with_send: true,
          comment: '',
          emails: this.prepareSuppliersEmails(newItems),
          project: parseInt(this.project),
          articles: newItems.map((article) => ({
            required_project_item_id: article.id,
            project: article.project_id,
            team: article.team_name,
            article_id: article.article.id,
            unit_id: article.article.unit_id,
            unit_name: article.article.article_unit?.name,
            quote_request_number: null,
            catalog_number: article.article.catalog_number,
            model: article.article.model,
            quantity_required: article.quantity_required,
            producer_short_name: article.article.producer?.short_name || null,
            producer_id: article.article.producer_id || '',
          })),
        });
      },
    },
  },


  mounted() {
    this.modalObj = new Modal(this.$refs.modalEle);
    this.fetchQuoteRequestsNumbers();
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    })
  },

  methods: {
    checkEmails(event) {
      const value = event.target.value;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex do walidacji podstawowej poprawności emaila
      const invalidPatterns = [
        /\.{2,}/,    // Dwie kropki lub więcej
        /,,+/,       // Więcej niż jeden przecinek obok siebie
        /,\./,       // Przecinek przed kropką
        /,\s*\./,    // Przecinek z opcjonalnymi spacjami przed kropką
        /\.,/,       // Kropka przed przecinkiem
      ];

      // Rozdziel string na podstawie przecinków, ignorując puste wpisy
      const emails = value.split(',').map(email => email.trim()).filter(email => email !== '');

      // Sprawdź, czy którykolwiek z niedozwolonych wzorców występuje w wejściu
      const hasInvalidPatterns = invalidPatterns.some(pattern => pattern.test(value));

      // Sprawdź, czy wszystkie maile są prawidłowe oraz brak niedozwolonych wzorców
      const areAllEmailsValid = !hasInvalidPatterns && emails.every(email => emailRegex.test(email));


      // Ustaw błędny mail, jeśli:
      // 1. Nie ma żadnego maila
      // 2. Albo któryś z maili jest nieprawidłowy
      this.emailsOk = !value || !areAllEmailsValid;

      // Zwróć wynik walidacji
      return areAllEmailsValid;
    },

    async getSuppliersEmails() {
      const suppliers = await axios.get(route('suppliers.get-all-suppliers'));
      return suppliers.data;
    },

    changeSupplierEmail(destination, index, value) {
      // jeśli jest to mail.to to pobierz tylko ten pierwszy, bo i tak może być tylko 1 wybrany
      if (destination === 'to') {
        value = value[0];
      }
      this.form.emails[index][destination] = value;
    },

    changeComment(event) {
      this.form.comment = event;
    },

    defaultDueDate() {
      const today = new Date();
      const nextWeek = new Date(today);
      nextWeek.setDate(today.getDate() + 7);
      const year = nextWeek.getFullYear();
      const month = String(nextWeek.getMonth() + 1).padStart(2, '0'); // Miesiąc jest 0-indeksowany
      const day = String(nextWeek.getDate()).padStart(2, '0');

      return `${day}.${month}.${year}`;
    },

    setQuoteRequestNumber(article, event) {
      article.quote_request_number = event?.value || null;
    },

    async fetchQuoteRequestsNumbers(event) {
      let search = event?.target.value || null;
      try {
        const response = await axios.get(route('quoteRequests.numbers.get'), {
          params: {
            quoteRequestNumber: search,
          }
        });

        if (response.data && response.data.quoteRequestNumbers) {
          this.formatQuoteRequestsNumbers(response.data.quoteRequestNumbers);
        }
      } catch (errors) {
        this.$emit('error', errors);
      }

      return false;
    },

    formatQuoteRequestsNumbers(numbers) {
      this.quoteRequestNumbers = numbers.map(number => ({
        label: number.request_number,
        value: number.request_number
      }));
    },

    addEmailsRow() {
      // Znajdź ostatni indeks w `form.emails`
      const lastIndex = Object.keys(this.form.emails).length;

      // Dodaj nowy wiersz
      this.$set(this.form.emails, lastIndex, {
        to: '',
        dw: ''
      });
    },

    removeEmailsRow(idx) {
      // Usuń wiersz z obiektu `form.emails`
      this.$delete(this.form.emails, idx);

      // Przebuduj obiekt `form.emails`, aby zachować ciągłość indeksów
      const updatedEmails = {};
      Object.keys(this.form.emails).forEach((key, newIdx) => {
        updatedEmails[newIdx] = this.form.emails[key];
      });

      // Przypisz zaktualizowany obiekt
      this.form.emails = updatedEmails;
    },

    saveQuoteRequest() {
      this.form.post(route('quoteRequests.store'), {
        onSuccess: (resp) => {
          this.$emit('added-success', 'Zapytanie ofertowe zostało utworzone.');
          this.$emit('updated-article', true);
          this.hide();
        },
        onError: (errors) => {
          console.log('errors:', errors);
          this.errors = errors;
          let errorMessages = Object.values(errors).flat().join(', ');
          this.$emit('added-error', 'Błąd podczas tworzenia zapytania: ' + errorMessages);
        }
      });
    },

    hasError(field) {
      const error = this.errors && this.errors[field];
      return error;
    },

    getErrorMessage(field) {
      if (!this.errors[field]) {
        return '';
      }

      if (/^Pole .*? jest wymagane\.$/.test(this.errors[field])) {
        return 'To pole jest wymagane.';
      }

      return 'To pole ma niepoprawną wartość';
    },

    show() {
      if (this.modalObj) {
        this.modalObj.show();
      }
    },
    hide() {
      if (this.modalObj) {
        this.modalObj.hide();
      }
    },

    setWithSend(value) {
      this.form.with_send = value;
    },

    prepareSuppliersEmails(items) {
      let suppliersEmails = {};
      let index = 0;

      items.forEach(item => {
        if (item.suppliers.length > 0) {
          item.suppliers.forEach(supplier => {
            suppliersEmails[index] = {
              to: supplier.customers_data.email,
              dw: ''
            };
            index++;
          });
        }
      });

      // Jeśli `suppliersEmails` jest pusty, dodaj domyślny obiekt
      if (Object.keys(suppliersEmails).length === 0) {
        suppliersEmails[0] = {to: '', dw: ''};
      }

      return suppliersEmails;
    },


    async getDefaultProducentForSelectedItem() {
      let prodcents = await axios.get(route('project.get-selected-producent-for-articles', {project: this.project}), {
        params: {articles: this.selectedItems}
      });
      this.producentSelectedInProject = [];
      if (prodcents.data[0].length > 0) {
        prodcents.data[0].forEach(innerArray => {
          innerArray.forEach(item => {
            if (item.supplier?.customer?.short_name && item.article_id) {
              if (!this.producentSelectedInProject[item.article_id]) {
                this.producentSelectedInProject[item.article_id] = [];
              }
              this.producentSelectedInProject[item.article_id].push(item.supplier.customer.short_name);
            }
          });
        });
      }
    },

    getProducerClass(articleId, producerName) {
      if (!this.producentSelectedInProject?.[articleId]) return 'text-danger';

      // Rozbij wartości producentów na tablicę
      const producers = this.producentSelectedInProject[articleId];

      if (producers.length === 0) return 'text-danger'; // Jeśli brak producentów, ustaw na czerwony

      if (producers[0] && producerName === producers[0]) return 'text-success'; // Pierwszy producent → zielony
      if (producers[1] && producerName === producers[1]) return 'text-warning'; // Drugi producent → pomarańczowy
      if (producers[2] && producerName === producers[2]) return 'text-warning'; // Trzeci producent → pomarańczowy

      return 'text-danger';
    },
  }
}
</script>


<style scoped>
.widthFirstTd {
  width: 150px;
}
</style>