<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="addQuoteRequestModal" ref="modalEle" tabindex="-1" role="dialog"
         aria-labelledby="modelTitleId"
         aria-hidden="true">
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Wyślij zapytanie do dostawców</h5>
            <button type="button" class="close ms-auto" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" v-if="this.hasSameQuoteRequestNumber()">
            <div v-if="form" class="container-fluid">
              <div class="row">

                <div class="col-md-12">
                  <label for="emails">Zapytanie będzie wysłane na maile:</label>

                  <div v-if="hasError(`emails`)" class="text-danger small">
                    {{ getErrorMessage(`emails`) }}
                  </div>

                  <table class="table">
                    <tbody>
                    <tr>
                      <td>
                        <div class="col-md-12">
                          <label>Maile dostawcy</label>
                          <ul>
                            <li v-for="(email, key) in form.emails" :key="email.to">
                              {{ email.to }}
                              <template v-if="email.dw">(DW: {{ email.dw }})</template>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="row mb-4">
                <div class="col-md-12">
                  <label>Komentarz do zamówienia</label>
                  <p>{{ form.comment }}</p>
                </div>
              </div>

              <template v-for="(article, idx) in form.articles" :key="article.id">
                <template v-if="!article.article_id">
                  Brak artykułu. Nie można stworzyć zamówienia.
                </template>
                <template v-else>
                  <table class="table order-list-table">
                    <tbody>
                    <tr>
                      <td colspan="2">#{{ idx + 1 }}</td>
                    </tr>
                    <tr>
                      <td class="widthFirstTd">Do zapytania ofertowego</td>
                      <td>{{ article.quote_request?.request_number || '' }}</td>
                    </tr>
                    <tr>
                      <td class="widthFirstTd">Nr katalogowy</td>
                      <td>{{ article.catalog_number }}</td>
                    </tr>
                    <tr>
                      <td class="widthFirstTd">Model</td>
                      <td>{{ article.model }}</td>
                    </tr>
                    <tr>
                      <td class="widthFirstTd">Potrzebna ilość</td>
                      <td>
                        {{ article.quantity_required }}
                      </td>
                    </tr>
                    <tr>
                      <td class="widthFirstTd">Producent</td>
                      <td>
                        <template v-if="article.producer_short_name">
                          <span class="font-weight-bolder">{{ article.producer_short_name }}</span>
                        </template>
                        <template v-else>
                          <i>Brak domyślnego producenta</i>
                        </template>
                      </td>
                    </tr>
                    <tr>
                      <td class="widthFirstTd">Dostawca</td>
                      <td>
                        <div v-if="hasError(`articles.${idx}.supplier`)" class="text-danger small">
                          {{ getErrorMessage(`articles.${idx}.supplier`) }}
                        </div>
                        <template v-if="article.supplier">
                          <span class="font-weight-bolder">{{ article.supplier.short_name || '' }}</span>
                        </template>
                        <template v-else>
                          <i>Brak domyślnego dostawcy</i>
                        </template>
                      </td>
                    </tr>

                    </tbody>
                  </table>
                </template>

              </template>
            </div>
          </div>
          <div v-else class="alert alert-danger">
            Wybrane zapytania muszą być w obrębie tego samego zapytania.
          </div>
          <div class="modal-footer" v-if="this.hasSameQuoteRequestNumber()">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              <i class="bi bi-x-square me-2"></i>Zamknij
            </button>
            <form @submit.prevent="sendQuote">
              <button type="submit" class="btn btn-primary" :disabled="form.processing || emailsOk">
                <i class="bi bi-save me-2"></i>Wyślij zapytanie
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {Modal, Tooltip} from "bootstrap";
import {useForm} from "@inertiajs/vue3";
import {DatePickerComponent} from "@syncfusion/ej2-vue-calendars";

export default {
  components: {'ejs-datepicker': DatePickerComponent},
  props: {
    selectedItems: {
      type: Object,
      default: () => {
      }
    },
  },

  data() {
    return {
      errors: {},
      emailsOk: false,
      quoteRequestNumbers: {},
      modalObj: null,
      dueDateFormat: 'dd.MM.yyyy',
      suppliersEmails: [],
      form: useForm({
        dueDate: this.defaultDueDate(),
        emails: [],
        articles: {}
      })
    }
  },

  watch: {
    selectedItems: {
      immediate: true,
      handler(newItems) {
        if (!newItems) {
          return false;
        }

        this.form = useForm({
          dueDate: this.defaultDueDate(),
          emails: this.prepareSuppliersEmails(newItems),
          articles: newItems.map((article) => ({
            project_id: article.quote_request.project_id,
            team_name: article.quote_request.team_name,
            article_id: article.article?.id,
            quote_request: article.quote_request,
            quote_request_item_id: article.quote_request_item_id,
            catalog_number: article.article?.catalog_number,
            model: article.article?.model,
            supplier: article.supplier,
            quantity_required: article.quantity_required,
            producer_short_name: article.article?.producer?.short_name || null,
            producer_id: article.article?.producer_id || '',
          })),
        });
      },
    },
  },


  mounted() {
    this.modalObj = new Modal(this.$refs.modalEle);
    // this.fetchQuoteRequestsNumbers();
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    })

    this.suppliersEmails = this.getSuppliersEmails();
  },

  methods: {
    hasSameQuoteRequestNumber(){
      if (!this.form.articles || this.form.articles.length === 0 || Object.keys(this.form.articles).length === 0) {
        return false; // Brak artykułów, zwracamy false
      }

      const firstRequestNumber = this.form.articles[0]?.quote_request?.request_number;
      return this.form.articles.every(article => article?.quote_request?.request_number === firstRequestNumber);

    },

    defaultDueDate() {
      const today = new Date();
      const nextWeek = new Date(today);
      nextWeek.setDate(today.getDate() + 7);
      const year = nextWeek.getFullYear();
      const month = String(nextWeek.getMonth() + 1).padStart(2, '0'); // Miesiąc jest 0-indeksowany
      const day = String(nextWeek.getDate()).padStart(2, '0');

      return `${day}.${month}.${year}`;
    },

    changeDeliveryDate(date) {
      this.form.dueDate = date.value;
    },

    prepareSuppliersEmails(items) {
      let suppliersEmails = [];
      items.forEach((item) => {
        // Dla każdego dostawcy dodaj maila do wysłania,
        // o ile dostawca ma ustawiony mail
        if (item.supplier && Object.keys(item.supplier).length > 0 && item.supplier.customers_data?.email) {
          suppliersEmails.push({
            to: item.supplier.customers_data?.email,
            dw: ''
          })
        }
      })

      // Jeśli `suppliers Emails` jest pusty, dodaj domyślny obiekt
      if (suppliersEmails.length === 0) {
        suppliersEmails.push({
          to: '',
          dw: ''
        });
      }

      return suppliersEmails;
    },


    async getSuppliersEmails() {
      const suppliers = await axios.get(route('suppliers.get-all-suppliers'));
      return suppliers.data;
    },

    checkEmails(event) {
      const value = event.target.value;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex do walidacji podstawowej poprawności emaila
      const invalidPatterns = [
        /\.{2,}/,    // Dwie kropki lub więcej
        /,,+/,       // Więcej niż jeden przecinek obok siebie
        /,\./,       // Przecinek przed kropką
        /,\s*\./,    // Przecinek z opcjonalnymi spacjami przed kropką
        /\.,/,       // Kropka przed przecinkiem
      ];

      // Rozdziel string na podstawie przecinków, ignorując puste wpisy
      const emails = value.split(',').map(email => email.trim()).filter(email => email !== '');

      // Sprawdź, czy którykolwiek z niedozwolonych wzorców występuje w wejściu
      const hasInvalidPatterns = invalidPatterns.some(pattern => pattern.test(value));

      // Sprawdź, czy wszystkie maile są prawidłowe oraz brak niedozwolonych wzorców
      const areAllEmailsValid = !hasInvalidPatterns && emails.every(email => emailRegex.test(email));


      // Ustaw błędny mail, jeśli:
      // 1. Nie ma żadnego maila
      // 2. Albo któryś z maili jest nieprawidłowy
      this.emailsOk = !value || !areAllEmailsValid;

      // Zwróć wynik walidacji
      return areAllEmailsValid;
    },

    async fetchQuoteRequestsNumbers(event) {
      let search = event?.target.value || null;
      try {
        const response = await axios.get(route('quoteRequests.numbers.get'), {
          params: {
            quoteRequestNumber: search,
          }
        });

        if (response.data && response.data.quoteRequestNumbers) {
          this.formatQuoteRequestsNumbers(response.data.quoteRequestNumbers);
        }
      } catch (errors) {
        this.$emit('error', errors);
      }

      return false;
    },

    formatQuoteRequestsNumbers(numbers) {
      this.quoteRequestNumbers = numbers.map(number => ({
        label: number.request_number,
        value: number.request_number
      }));
    },

    addEmailsRow() {
      // Znajdź ostatni indeks w `form.emails`
      const lastIndex = Object.keys(this.form.emails).length;

      // Dodaj nowy wiersz
      this.$set(this.form.emails, lastIndex, {
        to: '',
        dw: ''
      });
    },

    removeEmailsRow(idx) {
      // Usuń wiersz z obiektu `form.emails`
      this.$delete(this.form.emails, idx);

      // Przebuduj obiekt `form.emails`, aby zachować ciągłość indeksów
      const updatedEmails = {};
      Object.keys(this.form.emails).forEach((key, newIdx) => {
        updatedEmails[newIdx] = this.form.emails[key];
      });

      // Przypisz zaktualizowany obiekt
      this.form.emails = updatedEmails;
    },

    setDeliveryDate(idx, article, event) {
      this.form.articles[idx].delivery_date = event.value;
    },

    sendQuote() {
      this.form.post(route('quoteRequests.sendEmails'), {
        onSuccess: (resp) => {
          this.$emit('added-success', 'Zapytanie zostało wysłane');
          this.hide();
        },
        onError: (errors) => {
          console.log('errors:', errors);
          this.errors = errors;
          let errorMessages = Object.values(errors).flat().join(', ');
          this.$emit('added-error', 'Błąd podczas wysyłania zapytania: ' + errorMessages);
        }
      });
    },

    hasError(field) {
      return this.errors && this.errors[field];
    },

    getErrorMessage(field) {
      if (!this.errors[field]) {
        return '';
      }

      if (/^Pole .*? jest wymagane\.$/.test(this.errors[field])) {
        return 'To pole jest wymagane.';
      }

      return 'To pole ma niepoprawną wartość';
    },

    show() {
      if (this.modalObj) {
        this.modalObj.show();
      }
    },
    hide() {
      if (this.modalObj) {
        this.modalObj.hide();
      }
    },
  }
}
</script>


<style scoped>
.widthFirstTd {
  width: 150px;
}

.order-list-table:not(:first-child) {
  border-top: 2px solid #222;
}
</style>