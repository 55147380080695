<template>
  <AppLayout>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
      <div class="widget widget-six">
        <div class="widget-heading d-block">
          <div class="row">
            <div class="col-xl-12 col-md-12 col-sm-12 col-12 d-flex align-items-center">
              <h4 class="me-3 my-0">Zamówienia</h4>
              <div class="ms-auto">
                <div class="form-check form-switch form-switch-success">
                  <input class="form-check-input" type="checkbox" role="switch" id="showArchivedProjects"
                         style="height:1.5em; width:3em;"
                         v-model="archivedShown" @change="changeShowArchived()">
                  <label class="form-check-label" for="showArchivedProjects" style="margin: 3px 0 0 10px;">Pokaż
                    zarchiwizowane</label>
                </div>
              </div>
            </div>
            <div class="mt-3 row">

              <div class="col-md-12 col-lg-6">
                <label for="project-select">Wybierz projekt:</label>
                <select id="project-select" class="form-select form-select-sm" @change="updateSelectedProject"
                        v-model="selectedProjectId">
                  <option value="0" selected>Wszystkie projekty</option>
                  <option v-for="project in projects" :key="project.id" :value="project.id">
                    {{ project.label }}
                  </option>
                </select>
              </div>

              <div class="col-md-12 col-lg-6">
                <label for="team-select">Wybierz zespół:</label>
                <select id="team-select" class="form-select form-select-sm" @change="updateSelectedTeam"
                        v-model="selectedTeamName" :disabled="!selectedProjectId || (teams && teams.length === 0)">
                  <option value="">Wszystkie zespoły</option>
                  <option v-for="team in teams" :key="team.name" :value="team.name">
                    {{ team.name }}
                  </option>
                </select>
              </div>

            </div>
          </div>
        </div>

        <div class="widget-content">
          <div>
            <ejs-grid ref="orderListGrid" id="orderListGridId" height="500px"
                      :dataSource="orderListDataManager"
                      :allowTextWrap='true'
                      :allowExcelExport='false'
                      :allowPaging="true"
                      :allowSorting='true'
                      :allowFiltering='true'
                      :allowReordering='true'
                      :allowResizing='true'
                      :showColumnChooser='true'
                      :allowSelection='true'
                      :enablePersistence='false'
                      :allowGrouping='true'
                      :pageSettings="pageSettings"
                      :editSettings="editSettings"
                      :filterSettings="filterOptions"
                      :toolbar="toolbar"
                      :contextMenuItems="contextMenuItems"
                      :selectionSettings="selectionOptions"
                      :groupSettings='groupOptions'
                      :toolbarClick="clickToolbarHandler"
                      :contextMenuClick="clickContextMenuHandler"
                      :actionBegin="actionBeginHandler"
                      :actionComplete="actionCompleteHandler"
                      :dataBound='dataBound'
                      @contextMenuOpen="onContextMenuOpen"
                      :keyPressed="onKeyDown">
              <e-columns>
                <e-column type="checkbox" width="85"></e-column>
                <e-column field="id" headerText="ID" :allowEditing="false" isPrimaryKey="true"
                          :visible="false" :filter="filterDefault"></e-column>
                <e-column field="order_list.order_number" headerText="Numer zamówienia" :allowEditing="false"
                          :filter="filterDefault"></e-column>
                <e-column field="quote_request.request_number" headerText="Zapytanie ofertowe" :allowEditing="false"
                          :filter="filterDefault"></e-column>
                <e-column field="project.number" headerText="Projekt" :allowEditing="false" :visible="true"></e-column>
                <e-column field="team_name" headerText="Zespół" :allowEditing="false" :visible="true"></e-column>
                <e-column field="article.id" headerText="ID artykułu" :allowEditing="false" :visible="false"></e-column>
                <e-column field="article.catalog_number" headerText="Nr artykułu" :allowEditing="false"
                          :filter="filterDefault"></e-column>
                <e-column field="article.model" headerText="Model artykułu" :allowEditing="false"
                          :filter="filterDefault"></e-column>
                <e-column field="supplier.short_name" headerText="Wybrany dostawca" :allowEditing="false"
                          :filter="filterDefault"></e-column>
                <e-column field="send_to_mails" headerText="Maile" :allowEditing="false"
                          :filter="filterDefault"></e-column>
                <e-column field="send_to_mails_dw" headerText="Maile DW" :allowEditing="false"
                          :filter="filterDefault"></e-column>
                <e-column field="sent_date" headerText="Data wysłania maili" :allowEditing="false"
                          :filter="filterDefault" type="date" editType="datepickeredit" format="dd.MM.yyyy"></e-column>
                <e-column field="delivery_date_deadline" headerText="Ostateczny termin dostawy" :allowEditing="true"
                          :filter="filterDefault" format="dd.MM.yyyy" type="date" editType="datepickeredit"></e-column>
                <e-column field="quantity_required" headerText="Wymagana ilość" :allowEditing="true" format="N2"
                          :filter="filterDefault" editType="numericedit" min="0"
                          :edit="editNumbersPlusParams"></e-column>
                <e-column field="delivered_quantity" headerText="Dostarczona ilość" :allowEditing="true"
                          :filter="filterDefault" editType="numericedit" format="N2"
                          :edit="editNumbersPlusParams"></e-column>
                <e-column field="dispatched_quantity" headerText="Wydana ilość" :allowEditing="false"
                          :filter="filterDefault"></e-column>
                <e-column field="price_netto" headerText="Cena netto" :allowEditing="true"
                          :filter="filterDefault" editType="numericedit" format="N2"></e-column>
                <e-column field="vat" headerText="VAT" :allowEditing="true"
                          :filter="filterDefault" editType="numericedit" format="N0"></e-column>
                <e-column field="currency" headerText="Waluta" :allowEditing="true" :edit="currenciesParams"
                          :filter="filterDefault" editType="dropdownedit"></e-column>
                <e-column field="status.id" headerText="Status" :allowEditing="true" editType="dropdownedit"
                          foreignKeyValue='name' foreignKeyField='id' :dataSource="order_list_statuses"
                          :edit="orderItemStatusesParams" :filter="filterStatuses"></e-column>
                <e-column field="comments" headerText="Komentarz do pozycji" :allowEditing="true" :allowFiltering="false"
                          :filter="filterDefault"></e-column>
                <e-column field="order_list.comment" headerText="Komentarz do zamówienia" :allowEditing="true" :allowFiltering="false"
                          :filter="filterDefault"></e-column>
                <e-column field="created_at" headerText="Data utworzenia" :allowEditing="false" type="date"
                          format="dd.MM.yyyy" :filter="filterDefault"></e-column>
                <e-column field="created_by_name" headerText="Utworzone przez" :allowEditing="false"
                          :filter="filterDefault"></e-column>

                <template v-slot:deliverersShortNamesTemplate="{data}">
                  {{ formatDeliverers(data) }}
                </template>

              </e-columns>

              <e-aggregates>
                <e-aggregate>
                  <e-columns>
                    <e-column type="Custom" field="supplier.short_name" groupCaptionTemplate='Dostawcy: ${Custom}'
                              :customAggregate="aggregateListSuppliers"/>
                    <e-column type="Sum" field="price_netto" format="N2" groupCaptionTemplate='Suma: ${Sum}'/>
                  </e-columns>
                </e-aggregate>
              </e-aggregates>

            </ejs-grid>
          </div>

        </div>
      </div>
    </div>

    <MoveItemToWarehouseModal ref="moveItemToWarehouseModal" :project="selectedProjectId" :team="selectedTeamName"
                              :selectedItems="selectedRecordsToMove"
                              @update-grid="updateGrid"
                              @added-success="successMsg" @added-error="errorMsg"
                              @added-movement="this.$refs.orderListGrid.refresh()"/>

  </AppLayout>
</template>

<script>
import AppLayout from "../../Layouts/App.vue";
import {usePage} from "@inertiajs/vue3";
import {MultiSelect} from '@syncfusion/ej2-vue-dropdowns';
import {
  ColumnChooser,
  ColumnDirective,
  ColumnsDirective,
  ContextMenu,
  Edit,
  ExcelExport,
  Filter, ForeignKey,
  GridComponent,
  AggregatesDirective,
  AggregateDirective,
  Page,
  Reorder,
  Aggregate,
  Resize,
  Sort,
  Toolbar,
  Group,
} from '@syncfusion/ej2-vue-grids';
import {createElement} from "@syncfusion/ej2-base";
import {DataManager, WebMethodAdaptor, Query, Predicate} from '@syncfusion/ej2-data';
import {ComboBox, DropDownList} from "@syncfusion/ej2-dropdowns";
import moment from "moment";
import {ref} from "vue";
import MoveItemToWarehouseModal from "./MoveItemToWarehouseModal.vue";


let dropInstanceFilterUnits, statusElem, statusObj, statusFilter;
export default {

  components: {
    MoveItemToWarehouseModal,
    AppLayout,
    MultiSelect,
    'ejs-grid': GridComponent,
    'e-columns': ColumnsDirective,
    'e-column': ColumnDirective,
    "e-aggregates": AggregatesDirective,
    "e-aggregate": AggregateDirective
  },

  provide: {
    grid: [Page, Edit, Toolbar, ContextMenu, Sort, Filter, Reorder, Resize, ColumnChooser, ExcelExport, ForeignKey, Group, Aggregate]
  },

  props: {
    projects: Object,
    order_list_statuses: Object,
    units: Object,
    categories: Object,
    producers: Object,
    locations: Object,
    warehouses: Object,

  },

  beforeMount() {
    // Odczytanie danych z sesji
    const storedProjectId = sessionStorage.getItem('selectedProjectId');
    const storedTeam = sessionStorage.getItem('selectedTeam');
    this.archivedShown = sessionStorage.getItem('archivedShown');

    if (storedProjectId) {
      this.selectedProjectId = (storedProjectId === 'null' || storedProjectId === null) ? 0 : storedProjectId;
      this.updateTeams();
    }

    this.selectedTeamName = storedTeam;
    this.getRequiredProjectItemsForProject();
  },

  data() {

    return {
      shouldCollapseByInitial: true,
      teams: [],
      archivedShown: false,
      initialGrouping: true,
      orderListDataManager: [],
      fetchedOrders: [],
      selectedProjectId: 0,
      selectedTeamName: null,
      selectedRecordsToMove: null,
      addRequiredProjectItemsModalRef: ref(null),
      editArticleModalRef: ref(null),
      showEditArticleModal: false,
      selectedArticleToEdit: null,
      selectedRecordsToSendOrder: null,
      pageSettings: {pageSize: 50, pageCount: 5},
      filterOptions: {type: "Menu", operator: 'contains'},
      filterDefault: {operator: 'contains', params: {minLength: 2}},
      isRestoring: false,
      toolbar: this.getToolbar(),
      editSettings: this.getEditSettings(),
      selectionOptions: {checkboxOnly: true},
      contextMenuItems: this.getContextMenu(),
      groupOptions: {
        showGroupedColumn: true,
        columns: ['order_list.order_number'],
      },
      editNumbersPlusParams: {
        params: {
          min: 0,
          validateDecimalOnType: true
        }
      },

      currenciesParams: {
        create: () => {
          return document.createElement('input');
        },
        read: () => {
          return this.comboBoxObj.value;
        },
        destroy: () => {
          this.comboBoxObj.destroy();
        },
        write: (args) => {
          this.comboBoxObj = new ComboBox({
            dataSource: [
              {text: 'ZŁ', value: 'ZŁ'},
              {text: 'EUR', value: 'EUR'},
              {text: 'USD', value: 'USD'},
            ],
            fields: {text: "text", value: "value"},
          });
          this.comboBoxObj.appendTo(args.element);
        }
      },

      filterUnits: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.units,
              fields: {text: 'name', value: 'id'},
              placeholder: 'Szukaj jednostki',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterCategories: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.categories,
              fields: {text: 'name', value: 'id'},
              placeholder: 'Szukaj kategorii',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterProducers: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.producers,
              fields: {text: 'short_name', value: 'id'},
              placeholder: 'Szukaj producentów',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterSuppliers: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.suppliers,
              fields: {text: 'short_name', value: 'short_name'},
              placeholder: 'Szukaj dostawców',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterWarehouses: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.warehouses,
              fields: {text: 'short_name', value: 'id'},
              placeholder: 'Szukaj magazynu',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterStatuses: {
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            statusFilter = new DropDownList({
              dataSource: this.order_list_statuses,
              fields: {text: 'name', value: 'id'},
              placeholder: 'Szukaj Statusu',
              popupHeight: '200px',
              allowFiltering: true
            });
            statusFilter.appendTo(flValInput);
          },
          write: (args) => {
            statusFilter.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, statusFilter.text);
          }
        }
      },

      orderItemStatusesParams: {
        create: () => {
          statusElem = createElement('input');
          return statusElem;
        },
        read: () => {
          return statusObj.value;
        },
        destroy: () => {
          statusObj.destroy();
        },
        write: (args) => {
          statusObj = new DropDownList({
            dataSource: this.order_list_statuses,
            fields: {value: 'id', text: 'name'},
            enabled: true,
            placeholder: 'Wybierz status',
            floatLabelType: 'Never',
            value: args.rowData.status?.id,
            change: async (event) => {
              statusObj.value = await this.onChangeStatus(event, args.rowData);
            }
          });
          statusObj.appendTo(statusElem);
        }
      },

      inventoryStatusesParams: {
        create: () => {
          statusElem = document.createElement('input');
          return statusElem;
        },
        read: () => {
          return statusObj.text;
        },
        destroy: () => {
          statusObj.destroy();
        },
        write: () => {
          statusObj = new DropDownList({
            dataSource: this.inventory_statuses,
            fields: {value: 'name', text: 'name'},
            enabled: true,
            placeholder: 'Wybierz status',
            floatLabelType: 'Never'
          });
          statusObj.appendTo(statusElem);
        }
      },
    }
  },

  methods: {
    getEditSettings() {
      return {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        showConfirmDialog: false,
        showDeleteConfirmDialog: false,
        mode: 'Batch',
        newRowPosition: 'Top',
      };
    },

    getToolbar() {
      return [
        "Add",
        "Update",
        "Cancel",
        {text: "Zwiń", prefixIcon: 'e-collapse-2', id: 'collapse_all'},
        {text: "Rozwiń", prefixIcon: 'e-expand', id: 'expand_all'},
        {text: "Resetuj filtry", prefixIcon: 'e-reset', id: 'reset_filters'},
        {text: "Search", prefixIcon: 'e-search', id: "search", align: 'center'},
        {text: "Export do Excela", prefixIcon: 'e-excelexport', id: "excelexport", align: 'right'},
        "ColumnChooser",
      ];
    },

    clickToolbarHandler: async function (args) {
      /**
       * Collapse all i expand all
       */
      if (this.$refs.orderListGrid.ej2Instances.getRows().length > 0) {
        if (args.item.text === 'Zwiń') {
          this.$refs.orderListGrid.ej2Instances.groupModule.collapseAll();
        }
        if (args.item.text === 'Rozwiń') {
          this.$refs.orderListGrid.ej2Instances.groupModule.expandAll();
        }
      }

      /**
       * Niestandardowa akcja przy dodawaniu
       */
      if (args.item.text === 'Dodaj') {
        // args.cancel = true;

        // if (this.selectedProjectId && this.selectedTeamName)
        //   await this.$nextTick(() => {
        //     this.$refs.addRequiredProjectItemsModalRef.show();
        //   });
      }

      /**
       * Akcja przy usuwaniu artykułu.
       */
      if (args.item.text === 'Usuń') {
        const selectedRecords = this.$refs.orderListGrid.getSelectedRecords();
        if (selectedRecords.length > 0) {
          selectedRecords.forEach((record) => {
            this.$refs.orderListGrid.deleteRecord("id", record);
          });
        }
      }

      /**
       * Akcja przy kliknięciu resetowania filtrów
       */
      if (args.item.id === 'reset_filters') {
        this.$refs.orderListGrid.clearFiltering();
      }
    },

    /**
     * Akcja przy bindowaniu danych
     */
    dataBound: function () {
      // zamknij wszystkie pogrupowane pozycje
      if (this.shouldCollapseByInitial === true) {
        if (this.$refs.orderListGrid.ej2Instances.getRows().length > 0) {
          this.$refs.orderListGrid.ej2Instances.groupModule.collapseAll();
          this.shouldCollapseByInitial = false;
        }
      }
    },

    getContextMenu() {
      return [
        {text: 'Duplikuj pozycje', target: '.e-content', id: 'duplicate_order_item'},
        {separator: true},
        {text: 'Wyślij zamówienie', target: '.e-content', id: 'send_order', enabled: false},
        {separator: true},
        {text: 'Dyspozycja magazynowa', target: '.e-content', id: 'warehouse_disposition'},
      ];
    },

    onContextMenuOpen(args) {
      // Pobierz dane wiersza, w którym otwarto menu
      const rowData = this.$refs.orderListGrid.getRowInfo(args.event.target)?.rowData;
      const contextMenuInstance = args.element.ej2_instances[0];

      // Włączy/wyłącz opcje wysłania zamówienia
      if (rowData?.status?.name === "Zatwierdzone") {
        contextMenuInstance.enableItems(["Wyślij zamówienie"], true); // Włącz opcję
      } else {
        contextMenuInstance.enableItems(["Wyślij zamówienie"], false); // Wyłącz opcję
      }
    },

    clickContextMenuHandler(args) {
      // Wyślij zamówienie
      if (args.item.id === 'send_order') {
        this.selectedRecordsToSendOrder = this.$refs.orderListGrid.getSelectedRecords();
        this.$nextTick(() => {
          console.log(this.selectedRecordsToSendOrder);
          this.successMsg('TUTAJ BEDZIE WYSYŁANIE MAILI');
        });
      }

      if (args.item.id === 'warehouse_disposition') {
        this.selectedRecordsToMove = this.$refs.orderListGrid.getSelectedRecords();
        this.$refs.moveItemToWarehouseModal.show();
      }

      // Duplikuj pozycję
      if (args.item.id === 'duplicate_order_item') {
        Toast.fire({
          toast: false,
          position: 'center',
          icon: 'question',
          title: 'Klonowanie wierszy',
          html: 'Czy na pewno chcesz zduplikować wybrane wiersze?',
          showClass: {popup: 'animate__animated animate__fadeInDown'},
          hideClass: {popup: 'animate__animated animate__fadeOutUp'},
          timer: false,
          showConfirmButton: true,
          confirmButtonText: 'Tak',
          confirmButtonColor: 'success',
          showCancelButton: true,
          cancelButtonText: 'Nie',
        }).then((result) => {
          if (result.isConfirmed) {

            let rowsToDuplicate = this.$refs.orderListGrid.getSelectedRecords();
            this.$nextTick(() => {
              axios.post(route('orderLists.item.duplicate', {
                    orderListsItems: rowsToDuplicate
                  })
              ).then(response => {
                if (response.status === 200) {
                  this.successMsg('Wiersze skopiowane');
                  this.$refs.orderListGrid.refresh();

                } else {
                  this.errorMsg('Coś poszło nie tak: ', response.data.message);
                }
              }).catch(error => {
                console.log(error);
              });
            })
          }
        })
      }
    },

    updateSelectedProject(event) {
      this.selectedProjectId = Number(event.target.value);
      this.teams = null;
      this.selectedTeamName = null;
      if (this.selectedProjectId) {
        this.updateTeams()
      }

      this.getRequiredProjectItemsForProject();
    },

    updateTeams() {
      if (this.selectedProjectId) {
        const project = this.projects.find(project => project.id === Number(this.selectedProjectId));
        if (project) {
          this.teams = project.teams;
          this.selectedTeamName = null;
        }
      } else {
        this.teams = [];
        this.selectedTeamName = null;
      }
    },

    updateSelectedTeam(event) {
      this.selectedTeamName = event.target.value;
      this.getRequiredProjectItemsForProject();
    },

    getRequiredProjectItemsForProject() {
      sessionStorage.setItem('selectedProjectId', this.selectedProjectId);
      sessionStorage.setItem('selectedTeam', this.selectedTeamName);
      sessionStorage.setItem('archivedShown', this.archivedShown);

      this.orderListDataManager = new DataManager({
        url: route('orderLists.getList', {project: this.selectedProjectId, team: this.selectedTeamName}),
        adaptor: new WebMethodAdaptor(),
        batchUrl: route('orderLists.saveChanges'),
        updateUrl: route('orderLists.updateChanges'),
        crossDomain: true,
        headers: [
          {'X-CSRF-TOKEN': usePage().props.csrf_token},
          {'archivedShown': sessionStorage.getItem('archivedShown') || false}
        ],
      });
    },

    aggregateListSuppliers(args) {
      // Pobierz wszystkie wartości 'supplier.short_name' z grupy
      if (!args.items || !Array.isArray(args.items)) {
        return ''; // Zwróć pusty string, jeśli args.items nie istnieje lub nie jest tablicą
      }

      let suppliers = args.items
          .map(item => item.supplier?.short_name)
          .filter(name => name) // Usuwa null/undefined
          .filter((value, index, self) => self.indexOf(value) === index); // Usuwa duplikaty

      return suppliers.join(', '); // Połącz dostawców w jeden string
    },

    changeShowArchived() {
      sessionStorage.setItem('archivedShown', this.archivedShown ?? false);
      if (this.$refs.orderListGrid) {
        const query = new Query().addParams('archivedShown', this.archivedShown);
        document.getElementById('orderListGridId').ej2_instances[0].query = query;
      }
    },

    dateFormatter(field, data) {
      const date = data.created_at ? data.created_at : null;
      if (date) {
        try {
          return moment(date).format('DD.MM.YYYY HH:mm:ss');
        } catch (error) {
          return data
        }
      }

      return '';
    },

    onKeyDown: function (args) {
      let keyCode = args.which || args.keyCode;
      let isCtrlKey = (args.ctrlKey || args.metaKey) ? true : ((keyCode === 17));

      // code 83 to 'S'
      if (isCtrlKey && keyCode === 83) {
        args.preventDefault();

        this.updateGrid();
      }
    },

    actionBeginHandler(args) {
      //
      // WYSZUKIWARKA- wyszukiwanie wielu wartości z separatorem (przecinek
      //
      if (args.requestType === 'searching') {
        const keys = args.searchString.split(',');
        let flag = true;
        let predicate;

        if (this.$refs.orderListGrid.ej2Instances.searchSettings.key !== '') {
          this.values = args.searchString;
          keys.forEach((key) => {
            this.$refs.orderListGrid.ej2Instances.getColumns().forEach((col) => {
              if (flag) {
                predicate = new Predicate(col.field, 'contains', key, true);
                flag = false;
              } else {
                let predic = new Predicate(col.field, 'contains', key, true);
                predicate = predicate.or(predic);
              }
            });
          });

          this.$refs.orderListGrid.ej2Instances.query = new Query().where(predicate);
          this.$refs.orderListGrid.ej2Instances.searchSettings.key = '';
          this.refresh = true;
          this.valueAssign = true;
          this.removeQuery = true;
          this.$refs.orderListGrid.ej2Instances.refresh();
        } else {
          this.$refs.orderListGrid.ej2Instances.searchSettings.key = '';
          this.refresh = true;
          this.valueAssign = true;
          this.removeQuery = true;
          this.$refs.orderListGrid.ej2Instances.refresh();
        }
      }
    },

    actionCompleteHandler(args) {
      //
      // WYSZUKIWARKA - wyszukiwanie wielu wartości z separatorem (przecinek
      //
      if (args.requestType === "refresh" && this.valueAssign) {
        document.getElementById(
            this.$refs.orderListGrid.ej2Instances.element.id + "_searchbar"
        ).value = this.values;
        this.valueAssign = false;
      } else if (
          document.getElementById(
              this.$refs.orderListGrid.ej2Instances.element.id + "_searchbar"
          ).value === "" &&
          args.requestType === "refresh" &&
          this.removeQuery
      ) {
        this.$refs.orderListGrid.ej2Instances.query = new Query();
        this.removeQuery = false;
        this.$refs.orderListGrid.ej2Instances.refresh();
      }

      if (args.requestType === "batchsave") {  // Tylko dla operacji batchSave (zapis)
        if (args.changes && args.changes.changedRecords) {
          // Sprawdzamy, czy `args.batchChanges` istnieje i czy są zmienione rekordy
          this.successMsg("Dane zostały pomyślnie zapisane!");

        } else if (args.error) {
          // Jeśli wystąpił błąd, wywołaj `errorMsg` z wiadomością błędu
          this.errorMsg("Wystąpił błąd podczas zapisu danych: " + args.error);
        }
      }
    },

    async onChangeStatus(event, rowData) {
      // Jeżeli user zmienił status na Zrealizowane
      if (!event.itemData || event.itemData?.name !== 'Zrealizowane') {
        return;
      }


      // Sprawdź, czy dostarczona ilość === wydanej ilości. Jeśli NIE:
      if (parseFloat(rowData.delivered_quantity) !== parseFloat(rowData.dispatched_quantity)) {
        Toast.fire({
          toast: false,
          position: 'center',
          icon: 'warning',
          title: 'Zakończenie zamówienia',
          html: 'Aby dokończyć realizacje zamówienia, dostarczona ilość oraz wydana ilość musi być taka sama.',
          showClass: {popup: 'animate__animated animate__fadeInDown'},
          hideClass: {popup: 'animate__animated animate__fadeOutUp'},
          timer: false,
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Ok, rozumiem',
        });

        // cofnij do poprzedniego statusu
        return event.previousItemData.id;
      }

      const result = await Toast.fire({
        toast: false,
        position: 'center',
        icon: 'question',
        title: 'Zakończenie zamówienia',
        html: 'Czy na pewno chcesz zmienić status na zrealizowany? To zamówienie będzie zakończone.',
        showClass: {popup: 'animate__animated animate__fadeInDown'},
        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
        timer: false,
        showConfirmButton: true,
        confirmButtonText: 'Tak',
        confirmButtonColor: 'success',
        showCancelButton: true,
        cancelButtonText: 'Nie',
      });

      if (result.isConfirmed) {
        let response = await axios.post(route('orderLists.order-completed', {orderListItem: rowData.id}))
        if (response.status === 200) {
          this.successMsg(response);
        }
        this.$refs.orderListGrid.refresh();
      } else {
        // cofnij do poprzedniego statusu
        return event.previousItemData.id;
      }

    },

    updateGrid() {
      // aktualizuj
      let toolbar = document.getElementsByClassName('e-toolbar-left')[0];
      let buttons = toolbar.querySelectorAll('.e-toolbar-item');
      let updateButton = buttons[1].querySelector('button');


      if (updateButton) {
        updateButton.click();
      }
    },

    successMsg(resp) {
      Toast.fire({
        position: 'top-end',
        toast: true,
        icon: 'success',
        title: 'Sukces!',
        html: resp,
        showClass: {popup: 'animate__animated animate__fadeInDown'},
        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
        timer: 2500,
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true
      });
    },

    errorMsg(resp) {
      Toast.fire({
        position: 'top-end',
        toast: true,
        icon: 'warning',
        title: 'Błąd!',
        html: resp,
        showClass: {popup: 'animate__animated animate__fadeInDown'},
        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
        timer: 2500,
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true
      });
    },

    formatDeliverers(data) {
      // Paruj JSON i formatuj jako ciąg znaków
      try {
        const names = JSON.parse(data.deliverers_short_names);
        return names.join(', ');
      } catch (e) {
        // Jeśli dane nie są poprawnym JSON-em, zwróć je bez zmian
        return data.deliverers_short_names;
      }
    },
  }
}
</script>

<style scoped>
.not-editable {
  color: orangered;
}
</style>