<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="addRequiredProjectItemsModal" ref="modalEle" tabindex="-1" role="dialog"
         aria-labelledby="modelTitleId"
         aria-hidden="true">
      <div class="modal-dialog modal-full-width" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Dodaj pozycje do zapotrzebowania projektowego</h5>
            <Link :href="route('articles.index')"></Link>
            <button type="button" class="close ms-auto" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="container-fluid">
              <template v-if="!project || !team">
                <h4 class="text-warning">Najpierw wybierz projekt oraz zespół</h4>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                  <i class="bi bi-x-square me-2"></i>Zamknij
                </button>
              </template>

              <template v-else>
                <ejs-grid ref="addRequiredProjectItemsGrid" id="addRequiredProjectItemsGrid" height="500px"
                          :dataSource="articlesDataManager"
                          :allowTextWrap='true'
                          :allowExcelExport='false'
                          :allowPaging="true"
                          :allowSorting='true'
                          :allowFiltering='true'
                          :allowReordering='true'
                          :allowResizing='true'
                          :showColumnChooser='true'
                          :allowSelection='true'
                          :enablePersistence='false'
                          :pageSettings="pageSettings"
                          :editSettings="editSettings"
                          :filterSettings="filterOptions"
                          :toolbar="toolbar"
                          :selectionSettings="selectionOptions"
                          :rowDataBound="onRowDataBound"
                          :toolbarClick="clickToolbarHandler"
                          @queryCellInfo="customizeCell"
                          :cellSaved="cellSaveHandler"
                          @rowSelecting="rowSelecting"
                          @rowSelected="rowSelected"
                          @rowDeselected="rowDeselected">
                  <e-columns>
                    <e-column type="checkbox" width="85"/>
                    <e-column field="id" headerText="ID" width="100" :isPrimaryKey="true" :visible="false"
                              :allowEditing="false"/>
                    <e-column field="photos" headerText="Zdjęcia" width="150" :template="'imageTemplate'"
                              :allowEditing="false" :allowFiltering="false"></e-column>
                    <e-column field="catalog_number" headerText="Nr katalogowy" width="150" :filter="filterDefault"
                              :allowEditing="false"/>
                    <e-column field="model" headerText="Model" width="150" :filter="filterDefault"
                              :allowEditing="false"/>
                    <e-column field="article_unit.id" headerText="J.m." width="150" editType="dropdownedit"
                              :dataSource="units" foreignKeyValue='name' foreignKeyField='id'
                              :filter='filterUnits' :allowEditing="false"/>
                    <e-column field="description" headerText="Nazwa/Opis" :defaultValue="null" width="200"
                              :filter="filterDefault" :allowEditing="false"/>
                    <e-column field="link" headerText="Link" :defaultValue="null" :filter="filterDefault"
                              :template="'linkTemplate'" :allowEditing="false"/>
                    <e-column field="available_items.quantity" headerText="Ilość/Stany magazynowe" width="150"
                              type="number"
                              format="N2" :filter="filterDefault" :allowEditing="false"/>
                    <e-column field="quantity_required" headerText="Ilość wymagana" width="150" type="number"
                              :allowEditing="true" format="N2" :filter="filterDefault" :edit="editNumbersPlusParams"
                              editType="numericedit"/>
                    <e-column field="suppliers_short_names" headerText="Dostawcy" editType="string"
                              :allowEditing="true" :edit="suppliersParams" :dataSource="suppliers"
                              :filter="filterSuppliers"/>
                    <e-column field="comments" headerText="Uwagi" :allowEditing="true" :filter="filterDefault"/>
                  </e-columns>

                  <template v-slot:linkTemplate="{data}">
                    <a class="link link-primary" target="_blank" rel="noopener noreferrer" :href=data.link>{{
                        data.link
                      }}</a>
                  </template>

                  <template v-slot:imageTemplate="{ data }">
                    <div v-if="data.photos && data.photos.length > 0">
                      <img :src="`/${data.photos[0].photo_path}`" alt="Zdjęcie"
                           style="max-height: 100px; max-width: 150px"/>
                    </div>
                  </template>

                </ejs-grid>
              </template>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              <i class="bi bi-x-square me-2"></i>Zamknij
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import {Modal} from "bootstrap";
import {
  ColumnChooser,
  ColumnDirective,
  ColumnsDirective,
  ContextMenu,
  Edit,
  ExcelExport,
  Filter, ForeignKey,
  GridComponent,
  Page,
  Reorder,
  Resize,
  Sort,
  Toolbar,
  RowDD,
  VirtualScroll
} from '@syncfusion/ej2-vue-grids';
import {createElement} from "@syncfusion/ej2-base";
import {DataManager, WebMethodAdaptor} from '@syncfusion/ej2-data';
import {DropDownList} from "@syncfusion/ej2-dropdowns";
import {router, usePage} from "@inertiajs/vue3";
import {MultiSelect} from "@syncfusion/ej2-vue-dropdowns";
import {ref} from "vue";

let dropInstanceFilterUnits, delivererElem = ref(null), multiSelectObj = ref(null);

export default {
  components: {
    'ejs-grid': GridComponent,
    'e-columns': ColumnsDirective,
    'e-column': ColumnDirective
  },

  provide: {
    grid: [Page, Edit, Toolbar, ContextMenu, Sort, Filter, Reorder, Resize, ColumnChooser, ExcelExport, ForeignKey, RowDD, VirtualScroll]
  },

  props: {
    project: Number | String,
    team: Boolean | String,
    units: Object,
    suppliers: Object,
  },

  data() {

    return {
      articlesDataManager: [],
      modalObj: null,
      selectedArticles: null,
      pageSettings: {pageSize: 50, pageCount: 5},
      filterOptions: {type: "Menu", operator: 'contains'},
      filterDefault: {operator: 'contains', params: {minLength: 2}},
      toolbar: this.getToolbar(),
      editSettings: this.getEditSettings(),
      selectionOptions: {checkboxOnly: true, persistSelection: true},
      editNumbersPlusParams: {
        params: {
          min: 0,
          validateDecimalOnType: true
        }
      },
      filterUnits: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.units,
              fields: {text: 'name', value: 'id'},
              placeholder: 'Szukaj jednostki',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterSuppliers: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.producers,
              fields: {text: 'short_name', value: 'short_name'},
              placeholder: 'Szukaj dostawców',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      suppliersParams: {
        create: () => {
          this.delivererElem = document.createElement("input");
          return this.delivererElem;
        },
        read: () => {
          return this.multiSelectObj ? this.multiSelectObj.value.join(",") : "";
        },
        destroy: () => {
          if (this.multiSelectObj) {
            this.multiSelectObj.destroy();
            this.multiSelectObj = null;
          }
        },
        write: (args) => {
          const values = args.rowData.suppliers_short_names || ""; // Zabezpieczenie przed null
          const selectedValues = values ? values.split(",") : [];

          this.multiSelectObj = new MultiSelect({
            value: selectedValues,
            dataSource: this.suppliers,
            fields: {text: "short_name", value: "short_name"},
            placeholder: "Wybierz dostawców",
            mode: "Box",
            allowFiltering: true,
            popupHeight: "200px",
            closePopupOnSelect: false,
          });

          this.multiSelectObj.appendTo(this.delivererElem);
        },
      },
    }
  },

  mounted() {
    this.modalObj = new Modal(this.$refs.modalEle);
  },

  beforeMount() {
    this.getArticles();
  },

  methods: {

    getToolbar() {
      return [
        {text: "Dodaj wybrane pozycje (0)", prefixIcon: 'e-add', id: 'add_btn', disabled: true},
        {text: "Resetuj filtry", prefixIcon: 'e-reset', id: 'reset_filters'},
        {text: "Lista artykułów", prefixIcon: 'e-table-of-content', id: 'articles_list'},
        {text: "Search", prefixIcon: 'e-search', id: "search", align: 'center'},
        {text: "Odśwież", prefixIcon: 'e-refresh', id: "refresh", align: 'right'},
      ]
    },

    getEditSettings() {
      return {
        allowEditing: true,
        allowAdding: false,
        allowDeleting: false,
        showConfirmDialog: false,
        showDeleteConfirmDialog: false,
        mode: 'Batch',
        newRowPosition: 'Top',
      };
    },

    customizeCell(args) {
      // Sprawdź, czy komórka należy do kolumny "quantity_required"
      if (args.column.field === "quantity_required") {
        args.cell.classList.add("cell-editable");
      }

      // Sprawdź, czy komórka należy do kolumny "suppliers_short_names"
      if (args.column.field === "suppliers_short_names") {
        args.cell.classList.add("cell-editable");
      }

      // Sprawdź, czy komórka należy do kolumny "comments"
      if (args.column.field === "comments") {
        args.cell.classList.add("cell-editable");
      }
    },

    clickToolbarHandler: async function (args) {
      /**
       * Akcja przy kliknięciu resetowania filtrów
       */
      if (args.item.id === 'reset_filters') {
        this.$refs.addRequiredProjectItemsGrid.clearFiltering();
      }

      /**
       * Akcja przy kliknięciu listy artykułów
       */
      if (args.item.id === 'articles_list') {
        window.open(route('articles.index'), '_blank');
      }

      /**
       * Akcja przy kliknięciu dodawania wybranych pozycji
       */
      if (args.item.id === 'add_btn') {

        this.prepareDataToSubmit();

        // sprawdź czy któryś z zaznaczonych pozycji nie ma wpisanej lub jest 0 ilości wymaganej
        const hasZeroQuantity = Object.values(this.selectedArticles).some(item => !item.quantity_required);

        // info czy na pewno chcesz dodać bez wprowadzonej ilości wymaganej
        if (hasZeroQuantity) {
          const result = await Toast.fire({
            toast: false,
            position: 'center',
            icon: 'question',
            title: 'Brak ilości wymaganej',
            html: "Niektóre pozycje mają brakującą lub zerową ilość wymaganą. Dodać mimo to?",
            showClass: {popup: 'animate__animated animate__fadeInDown'},
            hideClass: {popup: 'animate__animated animate__fadeOutUp'},
            timer: false,
            showConfirmButton: true,
            confirmButtonText: 'Tak',
            confirmButtonColor: 'success',
            showCancelButton: true,
            cancelButtonText: 'Nie',
          });

          if (result.isConfirmed) {
            this.$refs.addRequiredProjectItemsGrid.endEdit();
            this.saveArticles();
          }
        } else {
          this.saveArticles();
        }
      }

      /**
       * Akcja przy kliknięciu odświeżania tabeli
       */
      if (args.item.id === 'refresh') {
        this.$refs.addRequiredProjectItemsGrid.ej2Instances.refresh();
      }
    },

    prepareDataToSubmit() {
      // Pobierz wybrane rekordy
      this.selectedArticles = this.$refs.addRequiredProjectItemsGrid.getSelectedRecords();

      // Pobierz zmiany wprowadzone w tabeli
      let batchChanges = this.$refs.addRequiredProjectItemsGrid.getBatchChanges();

      // Utwórz mapę zmian wprowadzonych w edycji (np. item_required i suppliers.id)
      let changedRecordsMap = {};
      batchChanges.changedRecords.forEach(record => {
        changedRecordsMap[record.id] = record;
      });

      // Dodaj brakujące pola do każdego wybranego artykułu
      this.selectedArticles = this.selectedArticles.map(article => {
        let updatedArticle = {...article};

        // Dodaj wartości z batchChanges, jeśli istnieją
        if (changedRecordsMap[article.id]) {
          updatedArticle = {...updatedArticle, ...changedRecordsMap[article.id]};
        }
        return updatedArticle;
      });


    },

    onRowDataBound(args) {
      if (args.data.deleted_at) {  // Sprawdź, czy rekord został "miękko" usunięty
        args.row.classList.add('deleted-row');
        args.isSelectable = false;
      }
    },

    updateToolbarCount() {
      /**
       * Sprawdź, ile wierszy jest zaznaczonych i modyfikuj button do dodawania wybranych pozycji
       */
          // Pobierz liczbę zaznaczonych wierszy
      const count = this.$refs.addRequiredProjectItemsGrid.getSelectedRecords().length;

      // Pobierz toolbar i znajdź przycisk "add_btn"
      const toolbar = this.$refs.addRequiredProjectItemsGrid.ej2Instances.toolbarModule.element;
      const addButton = toolbar.querySelector('[id="add_btn"]');

      // Zaktualizuj tekst przycisku
      if (addButton) {
        addButton.innerHTML = `<span class="e-btn-icon e-add e-icons"></span> Dodaj wybrane pozycje (${count})`;
      }

      // Aktywuj lub dezaktywuj przycisk
      if (count === 0) {
        this.$refs.addRequiredProjectItemsGrid.ej2Instances.toolbarModule.enableItems(['add_btn'], false);
      } else {
        this.$refs.addRequiredProjectItemsGrid.ej2Instances.toolbarModule.enableItems(['add_btn'], true);
      }
    },

    rowSelecting(args) {
      if (args.data.deleted_at) {
        args.cancel = true;
      }
    },

    rowSelected(args) {
      this.updateToolbarCount();
    },
    rowDeselected(args) {
      this.updateToolbarCount();
    },

    async getArticles() {
      this.articlesDataManager = new DataManager({
        url: route('articles.get', {project: this.selectedProjectId, team: this.selectedTeamName}),
        adaptor: new WebMethodAdaptor(),
        batchUrl: route('requiredProjectItems.saveChanges'),
        updateUrl: route('requiredProjectItems.updateChanges'),
        crossDomain: true,
        headers: [
          {'X-CSRF-TOKEN': usePage().props.csrf_token}
        ],
      });
    },


    saveArticles() {
      if (!this.project) {
        this.$emit("added-error", 'Nie został wybrany projekt.');
        return;
      }

      if (!this.team) {
        this.$emit("added-error", 'Nie został wybrany zespół.');
        return;
      }

      if (Object.keys(this.selectedArticles).length === 0) {
        this.$emit("added-error", 'Nie został wybrany żaden artykuł');
        return;
      }

      this.$inertia.post(route("requiredProjectItems.addArticleToList", {
                project: this.project,
                team: this.team,
                articles: this.selectedArticles
              }
          ), {},
          {
            preserveState: true,
            onSuccess: () => {
              this.$emit("added-article");
              this.$emit("added-success", "Artykuły zostały poprawnie dodane do zapotrzebowania.");
              this.selectedArticles = null;
              this.$refs.addRequiredProjectItemsGrid.clearSelection();
              this.hide();
            },
            onError: (resp) => {
              console.log(resp);
              console.log("Błąd podczas zapisywania danych:", Object.keys(resp)[0] ?? resp);
              this.$emit("added-error", Object.keys(resp)[0] ?? resp);
            },
          }
      );
    },

    cellSaveHandler(args) {
      if (args.column.field !== "suppliers_short_names") return;

      // Pobranie oryginalnych wartości z suppliers
      const originalShortNames = args.rowData.suppliers.map(s => s.short_name);

      // Pobranie zapisanej wartości
      const savedValues = args.value ? args.value.split(",") : [];

      // Znalezienie edytowanego wiersza
      const rowIndex = this.$refs.addRequiredProjectItemsGrid.getRowIndexByPrimaryKey(args.rowData.id)
      const rowElement = this.$refs.addRequiredProjectItemsGrid.ej2Instances.getRowByIndex(rowIndex);

      if (!rowElement) return;

      // Pobranie <td> dla `suppliers_short_names`
      const cellElement = rowElement.querySelector(`td[aria-colindex='${args.column.index + 1}']`);
      if (!cellElement) return;

      // Pobranie wartości jako tekstu
      const cellText = cellElement.textContent.trim();
      const cellValues = cellText.split(",");

      // Formatowanie koloru w `<td>` (zielone dla pasujących, czerwone dla nowych)
      const formattedText = cellValues
          .map(value => {
            const trimmedValue = value.trim();
            if (originalShortNames.includes(trimmedValue)) {
              return `${trimmedValue}`;
            } else {
              return `<mark>${trimmedValue}</mark>`;
            }
          })
          .join(", ");

      // Ustawienie nowego HTML w komórce
      cellElement.innerHTML = formattedText;
    },


    show() {
      if (this.modalObj) {
        this.modalObj.show();
      }
    },
    hide() {
      if (this.modalObj) {
        this.modalObj.hide();
      }
    },
  }
}

</script>

<style scoped>

</style>