<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="addQuoteRequestModal" ref="modalEle" tabindex="-1" role="dialog"
         aria-labelledby="modelTitleId"
         aria-hidden="true">
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Dodaj zamówienie</h5>
            <button type="button" class="close ms-auto" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div v-if="form" class="container-fluid">
              <div class="row">

                <div class="col-md-5">
                  <label for="dueDate">Ostateczny termin dostawy:</label>
                  <div class="mb-4" style="max-width: 300px;">
                    <ejs-datepicker
                        id="projectFileProgressId"
                        placeholder="Wybierz zakres dat"
                        :value="form.dueDate"
                        :format="dueDateFormat"
                        :weekNumber="true"
                        @change="changeDeliveryDate"
                    ></ejs-datepicker>
                  </div>
                </div>

                <div class="col-md-7">
                  <label for="emails">Wyślij zamówienie na poniższe maile:</label>

                  <div v-if="hasError(`emails`)" class="text-danger small">
                    {{ getErrorMessage(`emails`) }}
                  </div>

                  <table class="table">
                    <tbody>
                    <tr v-for="(email, key) in form.emails" :key="email.to">

                      <td>
                        <Vueform>
                          <TagsElement name="emailsTo[]" placeholder="Mail dostawcy"
                                       :max="1"
                                       :items="getSuppliersEmails"
                                       :default="[email.to]"
                                       :value="email.to"
                                       :create="true"
                                       @change="changeSupplierEmail('to', key, $event)"
                                       :search="true" autocomplete="off"/>
                        </Vueform>
                      </td>
                      <td>
                        <Vueform>
                          <TagsElement name="emailsDw[]" placeholder="Maile DW"
                                       :items="getSuppliersEmails"
                                       :default="[email.dw]"
                                       :value="email.dw"
                                       :create="true"
                                       @change="changeSupplierEmail('dw', key, $event)"
                                       :search="true" autocomplete="off"/>
                        </Vueform>
                      </td>
                      <td>
                        <!-- Przycisk "+" widoczny tylko w ostatnim wierszu -->
                        <template v-if="key === Object.keys(form.emails).length - 1">
                          <button type="button"
                                  class="ms-2 btn btn-outline-success"
                                  @click="addEmailsRow">
                            <i class="bi bi-plus-circle"></i>
                          </button>
                        </template>
                        <!-- a jeśli nie ostatni to pokaż przycisk "-" -->
                        <template v-else>
                          <button type="button"
                                  class="ms-2 btn btn-outline-danger"
                                  @click="removeEmailsRow(key)">
                            <i class="bi bi-dash-circle"></i>
                          </button>
                        </template>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <Vueform>
                    <TextElement name="comments" @change="changeComment($event)" autocomplete="off"
                                 label="Komentarz do zamówienia"
                                 placeholder="Komentarz będzie widoczny dla dostawcy po wysłaniu"/>

                  </Vueform>
                </div>
              </div>

              <template v-for="(article, idx) in form.articles" :key="article.id">
                <template v-if="!article.article_id">
                  Brak artykułu. Nie można stworzyć zamówienia.
                </template>
                <template v-else>
                  <table class="table order-list-table">
                    <tbody>
                    <tr>
                      <td colspan="2">#{{ idx + 1 }}</td>
                    </tr>
                    <tr>
                      <td class="widthFirstTd">Do zapytania ofertowego</td>
                      <td>{{ article.quote_request?.request_number || '' }}</td>
                    </tr>
                    <tr>
                      <td class="widthFirstTd">Nr katalogowy</td>
                      <td>{{ article.catalog_number }}</td>
                    </tr>
                    <tr>
                      <td class="widthFirstTd">Model</td>
                      <td>{{ article.model }}</td>
                    </tr>
                    <tr>
                      <td class="widthFirstTd">Potrzebna ilość</td>
                      <td>
                        <div v-if="hasError(`articles.${idx}.quantity_required`)" class="text-danger small">
                          {{ getErrorMessage(`articles.${idx}.quantity_required`) }}
                        </div>
                        <input class="form-control form-control-sm" type="number" step="0.1" min="0"
                               v-model="article.quantity_required" required>
                      </td>
                    </tr>
                    <tr>
                      <td class="widthFirstTd">Producent</td>
                      <td>
                        <template v-if="article.producer_short_name">
                          <span class="font-weight-bolder">{{ article.producer_short_name }}</span>
                        </template>
                        <template v-else>
                          <i>Brak domyślnego producenta</i>
                        </template>
                      </td>
                    </tr>
                    <tr>
                      <td class="widthFirstTd">Dostawca</td>
                      <td>
                        <div v-if="hasError(`articles.${idx}.supplier`)" class="text-danger small">
                          {{ getErrorMessage(`articles.${idx}.supplier`) }}
                        </div>
                        <template v-if="article.supplier">
                          <span class="font-weight-bolder">{{ article.supplier.short_name || '' }}</span>
                        </template>
                        <template v-else>
                          <i>Brak domyślnego dostawcy</i>
                        </template>
                      </td>
                    </tr>

                    </tbody>
                  </table>
                </template>

              </template>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              <i class="bi bi-x-square me-2"></i>Zamknij
            </button>
            <form @submit.prevent="saveOrder">
              <button type="submit" class="btn btn-primary" :disabled="form.processing || emailsOk">
                <i class="bi bi-save me-2"></i>Utwórz zamówienie
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {Modal, Tooltip} from "bootstrap";
import {useForm} from "@inertiajs/vue3";
import {DatePickerComponent} from "@syncfusion/ej2-vue-calendars";

export default {
  components: {'ejs-datepicker': DatePickerComponent},
  props: {
    project: Number | String,
    team: String,
    selectedItems: {
      type: Object,
      default: () => {
      }
    },
  },

  data() {
    return {
      errors: {},
      emailsOk: false,
      quoteRequestNumbers: {},
      modalObj: null,
      dueDateFormat: 'dd.MM.yyyy',
      suppliersEmails: [],
      form: useForm({
        dueDate: this.defaultDueDate(),
        comment: '',
        emails: [],
        articles: {}
      })
    }
  },

  watch: {
    selectedItems: {
      immediate: true,
      handler(newItems) {
        if (!newItems) {
          return false;
        }

        this.form = useForm({
          dueDate: this.defaultDueDate(),
          comment: '',
          emails: this.prepareSuppliersEmails(newItems),
          articles: newItems.map((article) => ({
            project_id: article.quote_request.project_id,
            team_name: article.quote_request.team_name,
            article_id: article.article?.id,
            quote_request: article.quote_request,
            quote_request_item_id: article.quote_request_item_id,
            catalog_number: article.article?.catalog_number,
            model: article.article?.model,
            supplier: article.supplier,
            quantity_required: article.quantity_required,
            producer_short_name: article.article?.producer?.short_name || null,
            producer_id: article.article?.producer_id || '',
          })),
        });
      },
    },
  },


  mounted() {
    this.modalObj = new Modal(this.$refs.modalEle);
    // this.fetchQuoteRequestsNumbers();
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    })

    this.suppliersEmails = this.getSuppliersEmails();
  },

  methods: {
    defaultDueDate() {
      const today = new Date();
      const nextWeek = new Date(today);
      nextWeek.setDate(today.getDate() + 7);
      const year = nextWeek.getFullYear();
      const month = String(nextWeek.getMonth() + 1).padStart(2, '0'); // Miesiąc jest 0-indeksowany
      const day = String(nextWeek.getDate()).padStart(2, '0');

      return `${day}.${month}.${year}`;
    },

    changeDeliveryDate(date) {
      this.form.dueDate = date.value;
    },

    prepareSuppliersEmails(items) {
      let suppliersEmails = [];
      items.forEach((item) => {
        // Dla każdego dostawcy dodaj maila do wysłania,
        // o ile dostawca ma ustawiony mail
        if (item.supplier && Object.keys(item.supplier).length > 0 && item.supplier.customers_data?.email) {
          suppliersEmails.push({
            to: item.supplier.customers_data?.email,
            dw: ''
          })
        }
      })

      // Jeśli `suppliers Emails` jest pusty, dodaj domyślny obiekt
      if (suppliersEmails.length === 0) {
        suppliersEmails.push({
          to: '',
          dw: ''
        });
      }

      return suppliersEmails;
    },

    changeSupplierEmail(destination, index, value) {
      // jeśli jest to mail.to to pobierz tylko ten pierwszy, bo i tak może być tylko 1 wybrany
      if (destination === 'to') {
        value = value[0];
      }
      this.form.emails[index][destination] = value;
    },

    changeComment(event) {
      this.form.comment = event;
    },

    async getSuppliersEmails() {
      const suppliers = await axios.get(route('suppliers.get-all-suppliers'));
      return suppliers.data;
    },

    checkEmails(event) {
      const value = event.target.value;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex do walidacji podstawowej poprawności emaila
      const invalidPatterns = [
        /\.{2,}/,    // Dwie kropki lub więcej
        /,,+/,       // Więcej niż jeden przecinek obok siebie
        /,\./,       // Przecinek przed kropką
        /,\s*\./,    // Przecinek z opcjonalnymi spacjami przed kropką
        /\.,/,       // Kropka przed przecinkiem
      ];

      // Rozdziel string na podstawie przecinków, ignorując puste wpisy
      const emails = value.split(',').map(email => email.trim()).filter(email => email !== '');

      // Sprawdź, czy którykolwiek z niedozwolonych wzorców występuje w wejściu
      const hasInvalidPatterns = invalidPatterns.some(pattern => pattern.test(value));

      // Sprawdź, czy wszystkie maile są prawidłowe oraz brak niedozwolonych wzorców
      const areAllEmailsValid = !hasInvalidPatterns && emails.every(email => emailRegex.test(email));


      // Ustaw błędny mail, jeśli:
      // 1. Nie ma żadnego maila
      // 2. Albo któryś z maili jest nieprawidłowy
      this.emailsOk = !value || !areAllEmailsValid;

      // Zwróć wynik walidacji
      return areAllEmailsValid;
    },

    async fetchQuoteRequestsNumbers(event) {
      let search = event?.target.value || null;
      try {
        const response = await axios.get(route('quoteRequests.numbers.get'), {
          params: {
            quoteRequestNumber: search,
          }
        });

        if (response.data && response.data.quoteRequestNumbers) {
          this.formatQuoteRequestsNumbers(response.data.quoteRequestNumbers);
        }
      } catch (errors) {
        this.$emit('error', errors);
      }

      return false;
    },

    formatQuoteRequestsNumbers(numbers) {
      this.quoteRequestNumbers = numbers.map(number => ({
        label: number.request_number,
        value: number.request_number
      }));
    },

    addEmailsRow() {
      // Znajdź ostatni indeks w `form.emails`
      const lastIndex = Object.keys(this.form.emails).length;

      // Dodaj nowy wiersz
      this.$set(this.form.emails, lastIndex, {
        to: '',
        dw: ''
      });
    },

    removeEmailsRow(idx) {
      // Usuń wiersz z obiektu `form.emails`
      this.$delete(this.form.emails, idx);

      // Przebuduj obiekt `form.emails`, aby zachować ciągłość indeksów
      const updatedEmails = {};
      Object.keys(this.form.emails).forEach((key, newIdx) => {
        updatedEmails[newIdx] = this.form.emails[key];
      });

      // Przypisz zaktualizowany obiekt
      this.form.emails = updatedEmails;
    },

    setDeliveryDate(idx, article, event) {
      this.form.articles[idx].delivery_date = event.value;
    },

    saveOrder() {
      this.form.post(route('orderLists.store'), {
        onSuccess: (resp) => {
          this.$emit('added-success', 'Zamówienie zostało utworzone.');
          this.hide();
        },
        onError: (errors) => {
          console.log('errors:', errors);
          this.errors = errors;
          let errorMessages = Object.values(errors).flat().join(', ');
          this.$emit('added-error', 'Błąd podczas tworzenia zamówienia: ' + errorMessages);
        }
      });
    },

    hasError(field) {
      return this.errors && this.errors[field];
    },

    getErrorMessage(field) {
      if (!this.errors[field]) {
        return '';
      }

      if (/^Pole .*? jest wymagane\.$/.test(this.errors[field])) {
        return 'To pole jest wymagane.';
      }

      return 'To pole ma niepoprawną wartość';
    },

    show() {
      if (this.modalObj) {
        this.modalObj.show();
      }
    },
    hide() {
      if (this.modalObj) {
        this.modalObj.hide();
      }
    },
  }
}
</script>


<style scoped>
.widthFirstTd {
  width: 150px;
}

.order-list-table:not(:first-child) {
  border-top: 2px solid #222;
}
</style>